import { css } from 'linaria';

export const termsOfService = css`
  margin: 1.5rem 0;

  h1 {
    word-wrap: break-word;
  }

  li > p {
    margin-bottom: 0;
  }
`;
